export default function AddRound({ width = '48', height = '48', color = '#aca8a8' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 48 48">
      <g id="Group_33345" data-name="Group 33345" transform="translate(-272 -1360)">
        <g id="Group_33221" data-name="Group 33221" transform="translate(272 1360)">
          <g id="Group_33220" data-name="Group 33220">
            <path id="Path_49755" data-name="Path 49755" d="M24,0A24,24,0,1,0,48,24,24.027,24.027,0,0,0,24,0Zm0,44.571A20.571,20.571,0,1,1,44.571,24,20.594,20.594,0,0,1,24,44.571Z" fill={color} />
          </g>
        </g>
        <g id="Group_33223" data-name="Group 33223" transform="translate(294.286 1372.571)">
          <g id="Group_33222" data-name="Group 33222">
            <path id="Path_49756" data-name="Path 49756" d="M239.428,134.1a1.714,1.714,0,0,0-1.714,1.714v19.429a1.714,1.714,0,1,0,3.429,0V135.809A1.714,1.714,0,0,0,239.428,134.1Z" transform="translate(-237.714 -134.095)" fill={color} />
          </g>
        </g>
        <g id="Group_33225" data-name="Group 33225" transform="translate(284.571 1382.285)">
          <g id="Group_33224" data-name="Group 33224">
            <path id="Path_49757" data-name="Path 49757" d="M155.238,237.714H135.809a1.714,1.714,0,1,0,0,3.429h19.429a1.714,1.714,0,1,0,0-3.429Z" transform="translate(-134.095 -237.714)" fill={color} />
          </g>
        </g>
      </g>
    </svg>

  );
}
