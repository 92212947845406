export default function renderIcon({ color = '#fdfefd' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="23.041"
      height="23.065"
      viewBox="0 0 23.041 23.065"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_160"
            data-name="Rectangle 160"
            width="23.041"
            height="23.065"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_137" data-name="Group 137" clipPath="url(#clipPath)">
        <path
          id="Path_675"
          data-name="Path 675"
          d="M2.376,23.065A2.4,2.4,0,0,1,.222,21.514,2.55,2.55,0,0,1,.884,18.54c1.9-1.868,3.758-3.788,5.686-5.63.988-.945,1.107-1.7.035-2.7-1.867-1.747-3.636-3.6-5.431-5.42C-.215,3.381-.369,1.894.728.786,1.871-.37,3.306-.251,4.717,1.141c1.82,1.8,3.657,3.579,5.425,5.427.946.988,1.708,1.117,2.713.055C14.688,4.687,16.6,2.826,18.51.964A2.663,2.663,0,0,1,22.3.7a2.632,2.632,0,0,1-.16,3.793c-1.84,1.928-3.7,3.84-5.631,5.677-1.1,1.043-1.033,1.809.056,2.82C18.439,14.727,20.212,16.575,22,18.4c1.275,1.3,1.376,2.835.312,3.9-1.094,1.091-2.565,1.016-3.883-.263-1.757-1.706-3.481-3.446-5.214-5.177-1.634-1.631-1.8-1.621-3.372-.024-1.718,1.745-3.472,3.454-5.194,5.195a2.941,2.941,0,0,1-2.273,1.035"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}
