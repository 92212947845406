export default function renderIcon({ color = '#fdfefd' }) {
  return (
    <svg
      id="Group_140"
      data-name="Group 140"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.687"
      height="17.838"
      viewBox="0 0 24.687 17.838"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_161"
            data-name="Rectangle 161"
            width="24.687"
            height="17.838"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_139"
        data-name="Group 139"
        transform="translate(0 0)"
        clipPath="url(#clipPath)"
      >
        <path
          id="Path_676"
          data-name="Path 676"
          d="M24.687,2.064a2.165,2.165,0,0,1-.7,1.435C19.529,7.938,15.1,12.4,10.636,16.834c-1.341,1.333-2.4,1.331-3.713.036C4.821,14.8,2.743,12.7.661,10.6A1.832,1.832,0,0,1,.3,8.188a1.934,1.934,0,0,1,2.155-.961,2.307,2.307,0,0,1,1.257.8c1.407,1.4,2.832,2.778,4.193,4.219.648.686,1.077.639,1.728-.022,3.609-3.665,7.259-7.29,10.9-10.924a6.743,6.743,0,0,1,1.133-1,1.992,1.992,0,0,1,3.02,1.766"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}
