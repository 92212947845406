export default function renderIcon({ color = '#fdfefd' }) {
  return (
    <svg
      id="Group_136"
      data-name="Group 136"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="26.253"
      height="29.075"
      viewBox="0 0 26.253 29.075"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_159"
            data-name="Rectangle 159"
            width="26.253"
            height="29.075"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_135"
        data-name="Group 135"
        transform="translate(0 0)"
        clipPath="url(#clipPath)"
      >
        <path
          id="Path_674"
          data-name="Path 674"
          d="M0,14.9c.18-.879.3-1.777.55-2.634A13.131,13.131,0,0,1,12.689,2.834l.452-.03V0l6.82,4.546-6.8,4.533v-2.8a8.915,8.915,0,0,0-4.54,1.086,9.712,9.712,0,1,0,14.158,9.769c.034-.285.028-.574.041-.861.052-1.139.533-1.829,1.383-1.987.919-.17,1.578.264,2.053,1.351v1.356a2.425,2.425,0,0,0-.1.346,12.842,12.842,0,0,1-8.77,10.99,24.282,24.282,0,0,1-3.209.746h-2.1a2.421,2.421,0,0,0-.347-.1A12.844,12.844,0,0,1,.764,20.261,24.239,24.239,0,0,1,0,16.993V14.9"
          transform="translate(0 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}
